import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './app';


//reset
//localStorage.removeItem('user');
//localStorage.removeItem('users');
console.log(localStorage.getItem('users'));
console.log(localStorage.getItem('user'));

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

ReactDOM.render(
    <App />,
  rootElement);

