import React, { useState, useEffect, useRef } from 'react';

import { QuadraticGraph } from "../2DmathGraph"

// Scaling Constants for Canvas
const SCALE = 0.1;
const OFFSET = 80;
const canvasWidth = window.innerWidth * .5;
const canvasHeight = window.innerHeight * .6;

function draw(myGraph) {

    myGraph.drawEquation(myGraph.quad, "blue", 5);
    myGraph.drawVerticalLine(myGraph.axisOfSymmetry, "red", 5, "dash");

    //draw  baseline graph
    myGraph.drawEquation(myGraph.quad1, "purple", 2);
    // myGraph.drawVerticalLine(0, "purple", 5, "dash");

}

function hasGraphChanged(graphTemp, myGraph) {
    if (myGraph.a1 != graphTemp.coefficients.a ||
        myGraph.b1 != graphTemp.coefficients.b ||
        myGraph.c1 != graphTemp.coefficients.c ||

        myGraph.is_intercept_correct != graphTemp.is_intercept_correct ||
        myGraph.is_axis_of_symetry_correct != graphTemp.is_axis_of_symetry_correct ||
        myGraph.is_vertex_correct != graphTemp.is_vertex_correct)

        return true;
    else
        return false;
}

function drawWhenMounted(canvasObj, coTarget, graphTemp, SetGraphTemp, event) {

    let myGraph = new QuadraticGraph(
        canvasObj,
        -10,
        -10,
        10,
        10,
        1,
        coTarget,
        graphTemp

    );
    myGraph.drawXAxis();
    myGraph.drawYAxis();

    const rect = canvasObj.getBoundingClientRect()
    const x = event.clientX - rect.left
    const y = event.clientY - rect.top

    //center and scale the mouse point
    let mouse_x = (x - myGraph.centerX) / myGraph.scaleX;
    let mouse_y = -(y - myGraph.centerY) / myGraph.scaleY;

    //console.log("mouse_x: " + mouse_x + " mouse_y: " + mouse_y);

    myGraph.decideQuadratic(mouse_x, mouse_y);

    //update temp graph features for next render; updates even if no actually change and see if useEffect can filter out
    //localStorage.setItem('graphTemp', JSON.stringify(graphTemp));
    //localStorage.setItem('myGraphCo', JSON.stringify({ a: myGraph.a, b: myGraph.b, c: myGraph.c }));

    if (hasGraphChanged(graphTemp, myGraph))
        SetGraphTemp({
            coefficients: { a: myGraph.a1, b: myGraph.b1, c: myGraph.c1 },
            is_intercept_correct: myGraph.is_intercept_correct,
            is_axis_of_symetry_correct: myGraph.is_axis_of_symetry_correct,
            is_vertex_correct: myGraph.is_vertex_correct
        });

    console.log(myGraph);


    draw(myGraph);

};


export function useQuadraticCanvas(iniCanvasObj) {
    const canvasRef = useRef(null);
    const [mousePosition, setMousePosition] = useState({ clientX: 0, clientY: 0, });
    const [coefficients, SetCoefficients] = useState(iniCanvasObj.initialQuadratic);


    //the correctness of the three features are calculated based on initialQuadratic and tempQuadratic
    const [graphTemp, SetGraphTemp] = useState({
        coefficients: iniCanvasObj.tempQuadratic, is_intercept_correct: false, is_axis_of_symetry_correct: false, is_vertex_correct: false
    });


    useEffect(() => {
        const canvasObj = canvasRef.current;
        const ctx = canvasObj.getContext('2d');
        // clear the canvas area before rendering the coordinates held in state
        ctx.clearRect(0, 0, canvasWidth, canvasHeight);

        //console.log(coefficients);
      // console.log(graphTemp);

        drawWhenMounted(canvasObj, coefficients, graphTemp, SetGraphTemp, mousePosition);
        // draw all coordinates held in state
        //coordinates.forEach((coordinate)=>{draw1(ctx, coordinate)
    }, [coefficients, graphTemp, mousePosition]);

    return [canvasRef, canvasWidth, canvasHeight, coefficients, SetCoefficients, setMousePosition, graphTemp, SetGraphTemp];
}

