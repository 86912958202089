import React, { useState } from 'react';

import {
    Nav,
    NavItem,
    NavLink
} from 'reactstrap';

export function UserLogin(props) {
    const isLoggedIn = props.user.isLoggedIn;

    if (isLoggedIn) {
        return (
            <Nav navbar>
                <NavItem>
                    <NavLink href="/account/register">{props.user.firstName + ' ' + props.user.lastName}</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink href="/account/logout">LogOut</NavLink>
                </NavItem>
            </Nav>);
    }

    return (
        <Nav navbar>
            <NavItem>
                <NavLink href="/account/register">Register</NavLink>
            </NavItem>
            <NavItem>
                <NavLink href="/account/login">Login</NavLink>
            </NavItem>
        </Nav>
    );
}

