export function authHeader() {
    // return authorization header with jwt token
    let user = JSON.parse(localStorage.getItem('user'));

    if (user && user.token) {

        if (user.googleId)
            return { 'Authorization': 'Bearer ' + user.token, 'elz_token_origin': 'google' };
        else
            return {
                'Authorization': 'Bearer ' + user.token
            }

    }
    else {
        return {};
    }
}