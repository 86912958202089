import React from 'react';
import { Redirect } from 'react-router-dom';
import { userService } from '../services';

function LogOutPage(props) {

    userService.logout();
    props.setIsLoggedIn(false);

    return <Redirect to="/" />;

}

export { LogOutPage };