// array in local storage for registered users
let quadratics = JSON.parse(localStorage.getItem('quadratics')) || [];

//in real backend, user is retrieved from jwt 
let user = JSON.parse(localStorage.getItem('user'))||[];
    
export function configureFakeBackendQuadratic() {
    let realFetch = window.fetch;
    window.fetch = function (url, opts) {
        const { method, headers } = opts;
        const body = opts.body && JSON.parse(opts.body);

        return new Promise((resolve, reject) => {
            // wrap in timeout to simulate server api call
            setTimeout(handleRoute, 1000);

            function handleRoute() {
                switch (true) {
                    case url.endsWith('/graph/math/quadratics') && method === 'POST':
                        return save();
                    case url.endsWith('/graph/math/quadratics') && method === 'PUT':
                        return update();
                    case url.endsWith('/graph/math/quadratics') && method === 'GET':
                        return getQuadratics();
                    case url.match(/\/graph\/\d+$/) && method === 'DELETE':
                        return deleteQuadratics();
                    default:
                        // pass through any requests not handled above
                        return realFetch(url, opts)
                            .then(response => resolve(response))
                            .catch(error => reject(error));
                }
            }

            //duplicated cos are allowed
            function save() {
                const quadratic = body;

                // assign quadratic id and a few other properties then save
                quadratic.id = quadratics.length ? Math.max(...quadratics.map(x => x.id)) + 1 : 1;
                quadratic.createdby = user.id;
                quadratics.push(quadratic);
                localStorage.setItem('quadratics', JSON.stringify(quadratics));

                return ok();
            }

            function update() {
                

                //return ok();
            }
    
            function getQuadratics() {

            }

            //if not used
            function deleteQuadratics() {

            }

            // helper functions

            function ok(body) {
                resolve({ ok: true, text: () => Promise.resolve(JSON.stringify(body)) });
            }

            function error(message) {
                resolve({ status: 400, text: () => Promise.resolve(JSON.stringify({ message })) });
            }

        });
    }
}