import React, { useState, useEffect } from 'react';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { Layout } from './Layout';
import { Home, About, Contact } from '../home';
import { Graph2D } from '../graph';
import { LoginPage, LogOutPage, RegisterPage, clientUser, browserUser } from '../user';
import { history } from '../_helpers';



function App() {
    const displayName = App.name;

    //if did not logout then auto logs in
    const storedClientUser = JSON.parse(localStorage.getItem('user'));

    let browserUser = {};

   // console.log(clientUser);
    if (storedClientUser) {

        browserUser = { ...storedClientUser, isLoggedIn: true };
        //todo:retrieve unfinished and assigned equations

    }
    else
        browserUser = {
            ...clientUser,
            isLoggedIn: false
        };

    const [user, setUser] = useState(browserUser);

    //base for reset
    // temp: unfinished work
    const quadraticGraph = {
        target:
        {
            a: 2, b: -4, c: 3
        },
        base: { a: 1, b: 0, c: 0 },
        temp: { a: 1, b: 0, c: 0 }
    };


    useEffect(() => {
        console.log(user);
    }, []);

    //not partial update
    function handleCreateUser(loggedinUser) {
        setUser({ ...loggedinUser, isLoggedIn:true });// fires triggers useEffect,  asynchronous

    }

    function handleIsLoggedInChange(isLoggedIn) {
        setUser({ ...user, isLoggedIn });// fires triggers useEffect,  asynchronous

        //load user graph parameters
        if (isLoggedIn) {
            //load
        }

        else
        {
            //set default

        };
    }

    return (
        <Layout user={user}>
            <Router history={history}>
                <Route exact path='/' component={Home} />
                <Route path='/home/about' component={About} />
                <Route path='/home/contact' component={Contact} />
                <Route path='/subject' component={Home} />
                <Route path='/graph/math/graph2D' ><Graph2D user={user} quadratic={quadraticGraph} /> </Route>
                <Route path='/account/login' ><LoginPage createUser={handleCreateUser} /> </Route>
                <Route path='/account/register' component={RegisterPage} />
                <Route path='/account/logout'> <LogOutPage user={user} setIsLoggedIn={handleIsLoggedInChange} /> </Route>

            </Router>
        </Layout>
    );
}

export { App };