import React, { useState } from 'react';
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    NavbarText
} from 'reactstrap';

import { UserLogin } from './UserLogin';

const NavMenu = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    console.log(props.user);
    const toggle = () => setIsOpen(!isOpen);

    return (
        <div>
            <Navbar className="navbar-dark bg-dark"  expand="md">
                <NavbarBrand href="/">Interactive Graphing</NavbarBrand>
                <NavbarToggler onClick={toggle} />
                <Collapse isOpen={isOpen} navbar>
                    <Nav className="mr-auto" navbar>
                        <NavItem>
                            <NavLink href="/">Home</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink href="home/about">About</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink href="home/contact">Contact</NavLink>
                        </NavItem>
                        <UncontrolledDropdown nav inNavbar>
                            <DropdownToggle nav caret>
                                Subject
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem tag="a" href="/graph/math/graph2D" >Math</DropdownItem>
                                <DropdownItem tag="a" href="/">Physics</DropdownItem>
                                <DropdownItem tag="a" href="/">Chemistry</DropdownItem>
                                <DropdownItem tag="a" href="/">Biology</DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </Nav>
                    <UserLogin user={props.user} />
                </Collapse>
            </Navbar>
        </div>
    );
}

export default NavMenu;