import React, { useState, useEffect } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { userService } from '../services';
import { GoogleLogin } from 'react-google-login';
import { config } from '../config';

function LoginPage(props) {
    const [inputs, setInputs] = useState({
        email: '',
        password: ''
    });
    const [submitted, setSubmitted] = useState(false);
    const { email, password } = inputs;
    const loggingIn = false;

    //location has an entry pushed from Graph2D page
    const location = useLocation();
    let history = useHistory();
    // reset login status
    useEffect(() => {
    }, []);

    function handleChange(e) {
        const { name, value } = e.target;
        setInputs(inputs => ({ ...inputs, [name]: value }));
    }

    function handleSubmit(e) {
        e.preventDefault();

        setSubmitted(true);
        if (email && password) {
            //authenticate and retrieve user
            // get return url from location state or default to home page
            const { from } = location.state || { from: { pathname: "/" } };
            console.log(location.state);

            userService.login(email, password)
                .then(
                    user => {
                        //creates user in localstorage
                        props.createUser(user);

                        alert('Login successful');
                        history.push(from);
                    },
                    error => {
                        alert('Login failed: ' + error);
                    }
                );

        }
    }
    //get called when initializing with error wrong redirect url
    function responseGoogle(response) {

        try {
            const { from } = location.state || { from: { pathname: "/" } };
            console.log(location.state);
            if (response.profileObj) {

                console.log(response);

                //map google user to our user

                let user = covertGoogleUser(response.profileObj);
                user.token = response.tokenId;
                console.log(user);
                //does not need validation at server
                user = { ...user, googleId: response.googleId }

                //elz user and third party authenticated user are the same by email
                //persist user to database
                // web api may return userid; for now, uses email as id
                //cache user
                userService.register(user)
                    .then(
                        ok => {
                            localStorage.setItem('user', JSON.stringify(user));
                            props.createUser(user);
                            alert('Google Login successful');
                            //console.log(location);
                            history.push(from);
                        },
                        error => {
                            alert('Google Login failed: ' + error);
                        }
                );

 

            }
        }
        catch (err) {
            alert(err.message);
        }
        // for debugging
        //console.log(response);

    }

    function covertGoogleUser(gUser) {
        let user = {};
        for (const propertyName of Object.getOwnPropertyNames(gUser)) {
            switch (propertyName) {
                case 'email':
                    user = { ...user, email: gUser[propertyName] };
                    break;
                case 'givenName':
                    user = { ...user, firstName: gUser[propertyName] };
                    break;
                case 'familyName':
                    user = { ...user, lastName: gUser[propertyName] };
                    break;
                default:
                // code block
            }
        }
        return user;
    }


    return (
        <div className="jumbotron">
            <div className="container">
                <div className="row justify-content-between">
                    <div className="col8">
                        <h2>Login</h2>
                        <form name="form" onSubmit={handleSubmit}>
                            <div className="form-group">
                                <label>Email</label>
                                <input type="text" name="email" value={email} onChange={handleChange} className={'form-control' + (submitted && !email ? ' is-invalid' : '')} />
                                {submitted && !email &&
                                    <div className="invalid-feedback">Email is required</div>
                                }
                            </div>
                            <div className="form-group">
                                <label>Password</label>
                                <input type="password" name="password" value={password} onChange={handleChange} className={'form-control' + (submitted && !password ? ' is-invalid' : '')} />
                                {submitted && !password &&
                                    <div className="invalid-feedback">Password is required</div>
                                }
                            </div>
                            <div className="form-group">
                                <button className="btn btn-primary">
                                    {loggingIn && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                    Login
                                </button>
                                <Link to="/Account/register" className="btn btn-link">Register</Link>
                            </div>
                        </form>
                    </div>
                    <div className="col-4">
                        <GoogleLogin
                            clientId={config.googleClientId}
                            buttonText="Login"
                            onSuccess={responseGoogle}
                            onFailure={responseGoogle}
                            cookiePolicy={'single_host_origin'}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export { LoginPage };