import React, { Component } from 'react';
import NavMenu from './NavMenu';

import { Container } from 'reactstrap';

export function Layout(props) {
    const displayName = Layout.name;
    console.log(props.user);
    return (
        <div className="d-flex justify-content-between flex-column h-100">
            <div className="p-2">
                <NavMenu user={props.user} />

                    {props.children}

            </div>

            <div className="p-2">
                <footer>
                    <p>&copy; 2021 - Efficient Learning Zone</p>
                </footer>
            </div>
        </div>
    );
}
