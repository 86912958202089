import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { history } from '../../_helpers';
import { userService } from '../services';
import { realUser } from '../model';

function RegisterPage(props) {

    const [user, setUser] = useState(realUser);

    const [submitted, setSubmitted] = useState(false);

    const registering = false;

    // reset login status
    useEffect(() => {
    }, []);

    function handleChange(e) {
        const { name, value } = e.target;
        console.log(value);
        setUser(user => ({ ...user, [name]: value }));
    }

    function handleSubmit(e) {
        e.preventDefault();

        setSubmitted(true);
        if (user.firstName && user.lastName && user.email && user.password) {

            /*
            localStorage.setItem("user", JSON.stringify(user));
            alert("Registration succeeded!");
            props.onUserChange(user);
            history.push('/Account/Login');
            */
            

            userService.register(user)
                .then(
                    user => {
                        history.push('/Account/Login');
                        alert('Registration successful');
                    },
                    error => {
                        alert('Registration failed: ' + error);
                    }
                );
        }
    }

    return (
        <div className="jumbotron">
            <div className="container">
                <div className="col-md-8 offset-md-2">
                    <div className="col-lg-8 offset-lg-2">
                        <h2>Register</h2>
                        <form name="form" onSubmit={handleSubmit}>
                            <div className="form-group">
                                <label>First Name</label>
                                <input type="text" name="firstName" value={user.firstName} onChange={handleChange} className={'form-control' + (submitted && !user.firstName ? ' is-invalid' : '')} />
                                {submitted && !user.firstName &&
                                    <div className="invalid-feedback">First Name is required</div>
                                }
                            </div>
                            <div className="form-group">
                                <label>Last Name</label>
                                <input type="text" name="lastName" value={user.lastName} onChange={handleChange} className={'form-control' + (submitted && !user.lastName ? ' is-invalid' : '')} />
                                {submitted && !user.lastName &&
                                    <div className="invalid-feedback">Last Name is required</div>
                                }
                            </div>
                            <div className="form-group">
                                <label>Email</label>
                                <input type="email" name="email" value={user.email} onChange={handleChange} className={'form-control' + (submitted && !user.email ? ' is-invalid' : '')} />
                                {submitted && !user.email &&
                                    <div className="invalid-feedback">Email is required</div>
                                }
                            </div>
                            <div className="form-group">
                                <label>Password</label>
                                <input type="password" name="password" value={user.password} onChange={handleChange} className={'form-control' + (submitted && !user.password ? ' is-invalid' : '')} />
                                {submitted && !user.password &&
                                    <div className="invalid-feedback">Password is required</div>
                                }
                            </div>
                            <div className="form-group">
                                <button className="btn btn-primary">
                                    {registering && <span className="spinner-border spinner-border-sm mr-1"></span>}
                        Register
                    </button>
                                <Link to="/Account/login" className="btn btn-link">Cancel</Link>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export { RegisterPage };