export const serverUser =  {
    id:0,
    firstName :'',
    lastName : '',
    email : '',
    password: '',
    googleId: ''
}

export const realUser = {
   // id: 0,
    firstName: '',
    lastName: '',
    email: '',
    password : '',
}

//httpclient 
export const clientUser = {

    firstName: '',
    lastName: '',
    email: '',
    token: ''
}

//browser client
 let  browserUser = {
    ...clientUser,
    isLoggedIn : false
}


//export { serverUser, realUser, clientUser, browserUser }