import React, { Component } from 'react';

export class Home extends Component {
  static displayName = Home.name;

  render () {
    return (

            <div className="jumbotron lead">
                <h1>Efficient Learning Zone</h1>
                <p>
                    Students can use interactive and graphical math pratice questions that help better grasp
                    concepts. Teachers can use existing questions and create new questions.
                    <br/><br/>
                </p>
                <b>Features of our system:</b> <br /><br />
                <ol>
                    <li>Math graph is built through interactive steps </li>
                    <li>Practice questions creation and reuse </li>
                    <li>Integrated scoring/grading</li>
                </ol> 
                <b>Technologies of our system:</b> <br /><br />
                <ol>
                    <li>Cloud </li>
                    <li>Google login </li>
                    <li>Containerized</li>
                    <li>Microservice architecture</li>
                    <li>Domain-driven design (DDD)</li>
                </ol> 
            <p><a href="/graph/math/graph2D" className="btn btn-primary btn-lg">Demo &raquo;</a></p>
            </div>

    );
  }
}
