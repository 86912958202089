import React, { Component } from 'react';


export class About extends Component {


    render() {
        return (
            <div className="jumbotron lead">
                <p> We are teachers, IT professionals and app developers. </p>
                <p>
                    We teachers want to find the best ways to teach and motivate students. We find our needs of tools for
                    better teaching still cannot be met, so we decide to take matters into our own hands by building
                    our own tools and systems.
                </p>
                <p>
                    We developers and IT professionals do our best to minimize the cost of building, running, maintaining
                    and extending the systems.
                </p>
            </div>
        );
    }
}
