import React, { useState } from 'react';
import { useQuadraticCanvas } from '../hooks';
import './Graph2D.css';
import { useLocation, useHistory } from 'react-router-dom';
import MathJax from 'react-mathjax';
import { quadraticEquation, getScore, getQuadraticFeaturesDoneList } from '../math';
import { Formik, Field, Form } from "formik";
import { quadraticService } from '../service';

/*
 * history.push('/Account/Login', { from: pathname });
 * second argument for passing state
 * https://reactrouter.com/web/api/history
 * 
 * */


function Graph2D(props) {

    const history = useHistory();
    const { pathname, location } = useLocation();

    //assigned to or created by the user
    //for demo, only one equation; production, a list of equations

    const iniCanvasObj = {};
    // console.log(props.quadratic);

    iniCanvasObj.initialQuadratic = props.quadratic.target;
    iniCanvasObj.tempQuadratic = props.quadratic.temp;

    const [canvasRef, canvasWidth, canvasHeight, coefficients, setCoefficients, setMousePosition, graphTemp, SetGraphTemp] = useQuadraticCanvas(iniCanvasObj);

    const score = getScore(graphTemp);
    const initCheckedFeatures = { checked: getQuadraticFeaturesDoneList(graphTemp) };

    const inlineFormula = quadraticEquation(coefficients);

    console.log(props.user);


    const handleCanvasClick = (event) => {
        // on each click get current mouse location 
        const currentCoord = { clientX: event.clientX, clientY: event.clientY };
        // add the newest mouse location to an array in state 
        setMousePosition(currentCoord);
    };



    /*
    const handleClearCanvas = (event) => {

        // clear the canvas area before rendering the coordinates held in state
        const canvasObj = canvasRef.current;
        const ctx = canvasObj.getContext('2d');
        ctx.clearRect(0, 0, canvasWidth, canvasHeight);

        // second argument for passing state
        //history.push('/Account/Login', { from: pathname });

    };
*/

    const handleCoChange = (event) => {
        const { name, value } = event.currentTarget;
        setCoefficients({ ...coefficients, [name]: value });

        //reset to base
        // quadratic feature resets by hardcoding; todo: calculated
        SetGraphTemp({
            coefficients: props.quadratic.temp,
            is_intercept_correct: false,
            is_axis_of_symetry_correct: false,
            is_vertex_correct: false
        });
    }


    return (
        <div className="container">
            <div className="row">
                <div className="col-9">
                    <div className="Canvas-main">
                        <canvas
                            className="App-canvas"
                            ref={canvasRef}
                            width={canvasWidth}
                            height={canvasHeight}
                            onClick={handleCanvasClick} />
                    </div>

                    <div className="Canvas-main">
                        <MathJax.Provider>
                            <div className="d-flex justify-content-between">
                                <div className="p-2"><MathJax.Node inline formula={inlineFormula} /></div>
                                <div className="p-2">
                                    <Formik
                                        initialValues={props.quadratic.target}
                                        onSubmit={

                                            async (co) => {
                                                co.CreatedBy = props.user.email;
                                                co.Type =1;
                                                console.log(co);
                                                quadraticService.save(co)
                                                    .then(
                                                        response => {
                                                            alert('equation saved ');
                                                        },
                                                        error => {
                                                            alert('equation failed to save: ' + error);
                                                        }
                                                    );
                                            }
                                        }
                                    >
                                        <Form>
                                            <label htmlFor="a">a: </label>
                                            <Field name="a" type="number" min="1" max="20"
                                                onBlur={e => {
                                                    // call the built-in handleBur
                                                    //handleBlur(e);
                                                    handleCoChange(e);
                                                }}
                                            />

                                            <label htmlFor="b" >b: </label>
                                            <Field name="b" type="number" min="-20" max="20"
                                                onBlur={e => {
                                                    handleCoChange(e);
                                                }}
                                            />
                                            <label htmlFor="c">c: </label>
                                            <Field name="c" type="number" min="-10" max="10"
                                                onBlur={e => {
                                                    handleCoChange(e);
                                                }}
                                            />
                                            <button className="btn btn-primary" type="submit">Save</button>
                                        </Form>
                                    </Formik>
                                </div>

                            </div>
                        </MathJax.Provider>
                    </div>
                </div>
                <div className="col-3">
                    <Formik
                        enableReinitialize
                        initialValues={
                            initCheckedFeatures
                        }
                        onSubmit={

                            async (values) => {
                                console.log(values);
                                /*quadraticService.save(co)
                                    .then(
                                        response => {
                                            alert('equation saved ');
                                        },
                                        error => {
                                            alert('equation failed to save: ' + error);
                                        }
                                    );*/
                            }
                        }
                    >
                        {({ values }) => (
                            <Form>
                                <div id="checkbox-group">Score: {score}</div>
                                <div className="d-flex justify-content-between" role="group" aria-labelledby="checkbox-group">
                                    <label className='p-2'>
                                        Intercept
                                    <Field type="checkbox" name="checked" value="Intercept" disabled />
                                    </label>
                                    <label className='p-2'>
                                        axis_of_symetry
                                    <Field type="checkbox" name="checked" value="Symetry" disabled />
                                    </label>
                                    <label className='p-2'>
                                        vertex
                                    <Field type="checkbox" name="checked" value="Vertex" disabled />
                                    </label>
                                </div>
                                <button className="btn btn-primary" type="submit">Submit</button>
                            </Form>
                        )}
                    </Formik>
                    <div>
                        <p>The purple curve is the start curve for all equations. The blue curve is the target.</p>
                        <p>Graphing rules: </p>
                        <ul>
                            <li>First, click  the area around the intercept</li>
                            <li>Second, click the area where the axis of the symmetry passes </li>
                            <li>Third, click the area around vertex</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

    );
}

export { Graph2D };