import { config } from '../config';
import { authHeader } from '../../_helpers';
import { useHistory } from 'react-router'

import { configureFakeBackendQuadratic } from '../_helpers';

// setup fake backend

//comment out to test user backend cuz it uses the same global fetch api
//configureFakeBackendQuadratic();

console.log(config.apiUrl)

export const quadraticService = {
    getAll,
    getById,
    update,
    save,
    delete: _delete
};


function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${config.apiUrl}/graph/math/quadratics`, requestOptions).then(handleResponse);
}

function getById(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${config.apiUrl}/graph/math/quadratics/${id}`, requestOptions).then(handleResponse);
}

function save(co) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(co)
    };

    return fetch(`${config.apiUrl}/graph/math/quadratics`, requestOptions).then(handleResponse);
}

function update(co) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(co)
    };

    return fetch(`${config.apiUrl}/graph/math/quadratics/${co.id}`, requestOptions).then(handleResponse);;
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    return fetch(`${config.apiUrl}/graph/math/quadratics/${id}`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}