

//sing MathML, LaTeX and ASCIIMathML markup.


function quadraticEquation(co) {

    //build equation dynamically
    let str = 'y =' + co.a;
    str += 'x^2';

    if (co.b > 0) {
        str += ' +' + co.b + 'x';
    } else if (co.b < 0) {
        str += co.b + 'x';
    } else {
        // ignore b term;
    }

    if (co.c > 0) {
        str += ' +' + co.c;
    } else if (co.c < 0) {
        str += co.c;
    } else {
        // ignore c term;
    }
    str += '';
    return str;
}

//
function getScore(graphTemp) {
    let score = 0;
    if (graphTemp.is_intercept_correct)
        score++;

    if (graphTemp.is_axis_of_symetry_correct)
        score++;

    if (graphTemp.is_vertex_correct)
        score++;

    return score;
}

function getQuadraticFeaturesDoneList(graphTemp) {
    const features = [];
    if (graphTemp.is_intercept_correct)
        features.push('Intercept');

    if (graphTemp.is_axis_of_symetry_correct)
        features.push('Symetry');

    if (graphTemp.is_vertex_correct)
        features.push('Vertex');

    return features;
   // return ['Intercept', 'Symetry'];
}


export { quadraticEquation, getScore, getQuadraticFeaturesDoneList };