import React, { Component } from 'react';


export class Contact extends Component {


    render() {
        return (
            <div className="jumbotron lead">
                <p>
                    We are located in Los Angeles, CA
                </p>

                <address>
                    <strong>Contact:</strong>   <a href="mailto:info@efficientlearningzone.com/">info@efficientlearningzone.com</a><br />
                </address>
            </div>
        );
    }
}
